import { driver } from 'driver.js';
import store from '../redux/store';
import {
  DEACTIVATE_TUTORIAL,
  SET_TIMER_UPDATE,
} from '../redux/redux-constants';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import closeIcon from '../assets/img/close-icon.svg';
import { BUSINESS_PLANNING_PROJECT_ID } from '../redux/reducers/player/gameStateReducer';
import useConfig from '../hooks/use-config';

const Tutorial = ({ currentMonth, isTutorialActive }) => {
  const { t } = useConfig();

  const driverObj = driver({
    showProgress: 'true',
    popoverClass: 'mc',
    nextBtnText: t`LABEL_TUTORIAL_BUTTON_NEXT`,
    prevBtnText: t`LABEL_BACK`,
    onPopoverRender: (popover, { config, state }) => {
      const closeButton = document.createElement`button`;
      closeButton.innerHTML = `<img src="${closeIcon}" alt="Close" style="width: 24px; height: 24px;" />`;
      closeButton.className = 'driver-popover-close-btn';
      popover.closeButton.replaceWith(closeButton);

      closeButton.addEventListener('click', () => {
        store.dispatch({ type: DEACTIVATE_TUTORIAL });
        driverObj.destroy();
      });
    },
    onDestroyed: () => {
      store.dispatch({ type: DEACTIVATE_TUTORIAL });
    },
    steps: [
      {
        popover: {
          popoverClass: 'mc welcome large',
          nextBtnText: 'Start',
          title: t`LABEL_TUTORIAL_WELCOME_TITLE`,
          description: t`LABEL_TUTORIAL_WELCOME_DESCRIPTION`,
          onNextClick: () => {
            store.dispatch({
              type: SET_TIMER_UPDATE,
              payload: 120,
            });
            driverObj.moveNext();
          },
        },
      },
      {
        element: '.sidebar-nav',
        popover: {
          title: t`LABEL_TUTORIAL_NAV_TITLE`,
          description: t`LABEL_TUTORIAL_NAV_DESCRIPTION`,
        },
      },
      {
        element: '.tutorial-timer',
        popover: {
          popoverClass: 'mc medium',
          side: 'bottom',
          align: 'center',
          title: t`LABEL_TUTORIAL_TIMER_TITLE`,
          description: t`LABEL_TUTORIAL_TIMER_DESCRIPTION`,
        },
      },
      {
        element: '.tutorial-round',
        popover: {
          side: 'bottom',
          align: 'center',
          title: t`LABEL_TUTORIAL_ROUND_TITLE`,
          description: t`LABEL_TUTORIAL_ROUND_DESCRIPTION`,
        },
      },
      {
        element: '#time-allocation',
        popover: {
          title: t`LABEL_TIME_ALLOCATION`,
          description: t`LABEL_TUTORIAL_TIME_ALLOCATION_DESCRIPTION`,
        },
      },
      {
        element: '#my-employees',
        popover: {
          popoverClass: 'mc small',
          title: t`LABEL_EMPLOYEES`,
          description: t`LABEL_TUTORIAL_EMPLOYEES_DESCRIPTION`,
        },
      },
      {
        element: '.view-profile',
        popover: {
          side: 'bottom',
          align: 'center',
          title: t`LABEL_VIEW_PROFILE`,
          popoverClass: 'mc small',
          description: t`LABEL_TUTORIAL_VIEW_PROFILE_DESCRIPTION`,
        },
      },
      {
        element: `.employee-skills`,
        popover: {
          popoverClass: 'mc small',
          title: t`LABEL_TUTORIAL_EMPLOYEE_SKILLS_TITLE`,
          description: t`LABEL_TUTORIAL_EMPLOYEE_SKILLS_DESCRIPTION`,
        },
      },
      {
        element: '.employee-engagement',
        popover: {
          popoverClass: 'mc medium',
          title: t`LABEL_ENGAGEMENT`,
          description: t`LABEL_TUTORIAL_ENGAGEMENT_DESCRIPTION`,
        },
      },
      {
        element: `#project${BUSINESS_PLANNING_PROJECT_ID}`,
        popover: {
          popoverClass: 'mc medium',
          title: t`PROJECT_NAME_BUSINESS_PLANNING`,
          description: t`LABEL_TUTORIAL_PROJECTS_DESCRIPTION`,
        },
      },
      {
        popover: {
          popoverClass: 'mc large',
          title: t`LABEL_TUTORIAL_END_TITLE`,
          description: t`LABEL_TUTORIAL_END_DESCRIPTION`,
          onNextClick: () => {
            store.dispatch({ type: DEACTIVATE_TUTORIAL });
            driverObj.destroy();
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (isTutorialActive && !driverObj.isActive()) {
      driverObj.drive();
    }
  }, [isTutorialActive, driverObj]);

  return null;
};

export default connect((state) => ({
  currentMonth: state.gameState.playerContext.currentMonth,
  isTutorialActive: state.player.isTutorialActive,
}))(Tutorial);
